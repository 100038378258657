.app-container {
    min-height: 100vh;
    background-color: #f3f4f6;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }
  
  .content-wrapper {
    max-width: 28rem;
    width: 100%;
    margin: 0 auto;
  }
  
  .title {
    font-size: 1.875rem;
    font-weight: 800;
    text-align: center;
    color: #111827;
    margin-bottom: 1rem;
  }
  
  .description {
    font-size: 0.875rem;
    text-align: center;
    color: #6b7280;
    margin-bottom: 2rem;
  }
  
  .donation-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .card-element {
    background-color: white;
    padding: 1rem;
    border-radius: 0.375rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  
  .donate-button {
    width: 100%;
    padding: 0.5rem 1rem;
    background-color: #2563eb;
    color: white;
    font-weight: 600;
    border-radius: 0.375rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .donate-button:hover {
    background-color: #1d4ed8;
  }
  
  .donate-button.loading {
    background-color: #9ca3af;
    cursor: not-allowed;
  }
  
  .error-message {
    background-color: #fee2e2;
    border-left: 4px solid #ef4444;
    color: #b91c1c;
    padding: 1rem;
    border-radius: 0.25rem;
  }
  
  .success-message {
    background-color: #d1fae5;
    border-left: 4px solid #10b981;
    color: #047857;
    padding: 1rem;
    border-radius: 0.25rem;
  }

  .amount-input {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .amount-input input {
    flex-grow: 1;
    margin-right: 10px;
  }
  
  .amount-input select {
    width: 80px;
  }